.video-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    padding: 100px 0;
}

.video-section__text-inner {
    display: flex;
    align-items: center;
}

.video-section__text-item {
    font-size: 1.1em;
    line-height: 1.1;
    text-align: justify;
    border-bottom: 1px solid var(--yellow);
    padding-bottom: 20px;
}

@media screen and (max-width: 967px) {
    .video-section {
        display: flex;
        flex-direction: column-reverse;
    }

    .video-section__text-item {
        border-bottom: none;
    }
}

@media screen and (max-width: 767px) {
    .video-section {
        padding: 50px 0;
    }
}