.adaptive-player {
    max-width: 600px;
    width: 100%;
    margin: 0;
}

.adaptive-player__video-inner {
    position: relative;
    padding-top: 56.25%;
    height: 0;
}

.adaptive-player__video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

@media screen and (max-width: 967px) {
    .adaptive-player {

    }
}