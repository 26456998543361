.footer {
    background: var(--gray-550);
}

.footer__inner {
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}

.footer__copyright {
    display: flex;
    justify-content: right;
    padding-bottom: 15px;
    font-size: 14px;
    color: #fff;
}

.footer__logo {
    margin-bottom: 30px;
}

.footer__phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.footer__phone-link {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 5px;
}

.footer__phone-link:hover {
    color: var(--yellow);
}

.footer__address {
    font-size: 14px;
    line-height: 1.2;
}

.footer__address:not(:last-of-type) {
    margin-bottom: 30px;
}

.footer__address-title {
    color: var(--gray-400);
    margin-bottom: 10px;
}

.footer__address-text {
    font-size: 14px;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .footer__inner {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
    }

    .footer__col-2 {
        margin-bottom: 20px;
    }

    .footer__phone {
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .footer__copyright {
        justify-content: left;
        padding-left: 20px;
    }
}
