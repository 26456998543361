.map-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.map-section__item {
    position: relative;
    width: 672px;
    height: 612px;
}

.map-section__text-inner {
    display: flex;
    align-items: center;
}

.map-section__text-item {
    font-size: 1.1em;
    line-height: 1.1;
    text-align: justify;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--yellow);
}

@media screen and (max-width: 967px) {
    .map-section {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 710px) {
    .map-section__item {
        width: 100%;
        height: 400px;
    }
}