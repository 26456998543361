.gallery-box {
    padding-bottom: 100px;
}

.gallery-box__inner {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding-bottom: 50px;
}

.gallery-box__item {
    position: relative;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 5px 20px 50px rgba(30, 30, 30, .1);
    box-shadow: 5px 20px 50px rgba(30, 30, 30, .1);
    grid-column: 1 span;
}

.gallery-box__top {
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

.gallery-box__img {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-box__content {
    padding: 10px;
    margin: 20px;
}

.gallery-box__title {
    font-weight: 600;
    font-size: 22px;
    color: var(--gray-600);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--yellow);
}

.gallery-box__text {
    padding-top: 10px;
}

.gallery-box__btn {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 967px) {
    .gallery-box__inner {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .gallery-box__inner {
        grid-gap: 20px;
    }
}

@media screen and (max-width: 767px) {
    .gallery-box {
        grid-column: 2 span;
    }

    .gallery-box__content {
        padding: 15px;
    }

    .gallery-box__inner {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .gallery-box__content {
        padding: 10px;
    }
}