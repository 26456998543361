/* Переменные CSS */

:root {
  --yellow-lvl-400: #fdbf00;
  --yellow-lvl-500: #fdae00;
  --yellow-lvl-600: #fd9600;
  --gray-lvl-400: #dcdcdc; /* 220 */
  --gray-lvl-450: #cdcdcd; /* 205 */
  --gray-lvl-500: #bbb; /* 187 */
  --gray-lvl-700: #959595; /* 149 */
  --gray-lvl-800: #636363; /* 99 */
  --gray-lvl-900: #2a2a2a; /* 42 */
  --red-lvl-400: #f00;
  --brown-lvl-400: #6f4a00;

  /* Цвета бренда */
  --green: #83cc21;
  --green-100: #4cca00;
  --yellow: #fc0;
  --yellow-light: #eab800;
  --orange: #fa6239;
  --blue: #008bff;

  /* Серые оттенки */
  --gray-100: #f7f7f7;
  --gray-200: #f1f1f1;
  --gray-250: #ebebeb;
  --gray-300: #e6e6e6;
  --gray-400: #999;
  --gray-500: #484848;
  --gray-550: #3f3f3f;
  --gray-600: #333;
}

/* Другие главные стили */
html {
  /* Cглаживание шрифтов на MAC OSX - ВНИМАНИЕ!!! Делают шрифт немного тоньше!!! */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  color: #000;
  background: #fff;
  font-family: "Ubuntu", sans-serif;
}

a {
  text-decoration: none;
}

/* Настройка враппера */

.wrapper {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

/* Настройки брейкпоинтов враппера */

@media screen and (min-width: 1217px) {
  .wrapper {
    width: 1170px;
  }
}

@media screen and (max-width: 1216px) {
  .wrapper {
    width: 1140px;
  }
}

@media screen and (max-width: 1169px) {
  .wrapper {
    width: 960px;
  }
}

@media screen and (max-width: 980px) {
  .wrapper {
    padding: 0 20px;
  }
}

@media screen and (max-width: 967px) {
  .wrapper {
    width: 768px;
    padding: 0;
  }
}

@media screen and (max-width: 798px) {
  .wrapper {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
  }
}

/* Скрытие блока в определенном диапазоне разрешений */

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1169px) {
  .hidden-md {
    display: none !important;
  }
}

@media screen and (min-width: 1170px) {
  .hidden-lg {
    display: none !important;
  }
}

/* Скрытие блока в десктопной или мобильной версии */

@media screen and (min-width: 768px) {
  .hidden-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}
