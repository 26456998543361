.header-banner {
  height: 80vh;
  position: relative;
  width: 100%;
  top: 0;
  background: url("../img/bg_header.jpeg") center no-repeat;
  background-size: cover;
  z-index: 1;
  color: white;
  margin-bottom: 50px;
}

.header-banner::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(7, 7, 13, 0.6);
  z-index: 1;
}

.header-banner__inner {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.header-banner__title {
  font-size: 1.5em;
  margin-bottom: 2em;
}

.header-banner__text {
  max-width: 550px;
  font-size: 1.3em;
  line-height: 1.3;
  margin-bottom: 3em;
}

.header-banner__btn {
  font-size: 1.3em;
}

.header-banner__widget {
  position: absolute;
  width: 100%;
  z-index: 2;
  padding-left: 25px;
}

@media screen and (max-width: 1169px) {
  .header-banner {
    font-size: 14px;
  }
}

@media screen and (max-width: 959px) {
  .header-banner {
    font-size: 13px;
  }
}

@media screen and (max-width: 959px) {
  .header-banner__inner {
    padding: 15px;
  }

  .header-banner {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .header-banner {
    height: 100vh;
  }
}