.repair-section {
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-gap: 40px;
    padding: 50px 0;
}

.repair-section__text-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.repair-section__text-item {
    font-size: 1.5em;
    line-height: 1.3;
    text-align: justify;
    padding-bottom: 40px;
}

.repair-section__btn {
    display: flex;
    justify-content: center;
}

.repair-section__img {
    position: relative;
    overflow: hidden;
    max-height: 612px;
}

.repair-section__img-item {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
}

@media screen and (max-width: 1169px) {
    .repair-section__text-item {
        font-size: 1.3em;
        line-height: 1.3;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .repair-section {
        display: flex;
        flex-direction: column-reverse;
    }

    .repair-section__img {
        max-height: 400px;
    }
}